<template>
  <!--  其他配件的Modal框  -->
  <div class="select-item-modal" v-if="visible">
    <div class="item-modal-box">
      <md-icon :name="'close'" size="lg" class="close-btn" @click="controlModal(false)"></md-icon>
      <div class="modal-title">
        <input v-model="searchedKeyword" placeholder="搜索配件名称" class="search-box"/>
        <md-button size="small" type="primary" icon="search" inline @click="getPartsList"></md-button>
      </div>

      <md-check size="lg" :name="''" >
        <div class=" check-content check-list-title">
          <p class="parts-text parts-title parts-name">配件名称</p>
          <div class="parts-text parts-title parts-image-box">配件图片</div>
<!--          <p class="parts-text parts-title parts-prise">配件价格</p>-->
        </div>
      </md-check>
      <div class="item-inner-modal-box">
        <md-check-group v-model="checkResult">
          <md-check size="md" :name="item" v-for="(item, index) in list" :key="index">
            <div class="flex check-content">
			  <p class="parts-text parts-name">{{item.part_code}}</p>
              <p class="parts-text parts-name">{{item.part_name}}</p>
              <div class="parts-image-box">
                <img :src="'http://www.icnctp.com/img/'+item.part_img" alt="" class="parts-img">
              </div>
<!--              <p class="parts-text parts-prise">{{item.part_price}}</p>-->
            </div>
          </md-check>
        </md-check-group>
      </div>

      <md-button type="primary" class="setting-btn" @click="confirmSetting">确认</md-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ApiConfig from "../assets/config/api.config";
const formDataHeader = {'Content-Type': 'application/x-www-form-urlencoded'};
export default {
  name: "configModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    exParts: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      exPartsIds: '',
      searchedKeyword: '',
      list: [],
      checkResult: []
    }
  },
  mounted() {
    this.getPartsList();
  },
  methods: {
    // 获取配件信息
    getPartsList() {
      let params = new FormData();
      params.append('type', 'parts');
      params.append('part_ids', this.exPartsIds)
      params.append('part_name', this.searchedKeyword);
      axios.post(ApiConfig.getPartsInfo, params, {headers: formDataHeader}).then(({data}) => {
		console.log("data",data)
        if(data.code === 0) {
          this.list = data.content.parts;
        }

      })
    },
    // 控制Modal框显示
    controlModal(bool) {
      this.$emit('close', bool);
    },
    // 确认设置项
    confirmSetting() {
      // 将选中的对象的修整后传出去
      let result = [];
      this.checkResult.map(i => {
        if(i) {
          result.push(i);
        }
      })
      this.$emit('getConfigSetting', result)
      this.controlModal(false);
    }
  },
  watch: {
    exParts: function(val) {
      this.checkResult = val;
      if(val) {
        let partsIds = []
        val.map(v => {
          partsIds.push(v.part_id);
        })
        this.exPartsIds = partsIds.join(',');
        this.getPartsList();
      }
    }
  }
}
</script>

<style lang="less" scoped>
.modal-title {
  text-align: left !important;
  font-size: 0.35rem;
  display: flex;
  align-items: center;
  .search-box {
    height: 0.8rem;
    margin-right: 0.3rem;
    width: 60%;
  }
}
.item-inner-modal-box {
  max-height: 60vh;
  overflow: scroll;
}
.md-check-group {
  font-size: 0.8rem !important;
  .md-check-label {
    font-size: 0.7rem;
  }
}
.parts-img {
  width: 1rem;
  height: 1rem;
}
.md-check {
  margin: 0 !important;
}
.check-content {
  font-weight: 300;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 0.2rem 0;
  justify-content: space-between;
  .parts-image-box {
    width: 1.5rem !important;
    display: flex;
    align-items: center;
	justify-content: end;
  }
  .parts-text {
    font-size: 0.35rem;
    // width: 1.8rem;
  }
  .parts-name {
    width: 5.5rem;
  }
}
.parts-title {
  font-size: 0.35rem !important;
  padding-bottom: 0.2rem;
}
.check-list-title {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setting-btn {
  margin-top: 1rem;
}

</style>
