<template>
  <div class="full-container">
    <div class="setting-container">
      <div class="flex nowrap setting-item" v-for="(item,index) in formController">
        <p class="setting-tips">{{ item.name }}：</p>
        <div class="flex-no-wrap fields-item">
        <!--    input setting     -->
          <div class="input-box">
            <input :type="item.type" class="fields-input" v-model="partsForm[item.key]" v-if="item.type === 'text'" @input="searchInput" @focus="openSearch(item,index)" @blur='item.searchr=false'/>
            <md-stepper v-model="partsForm[item.key]" :min="0" :max="maxNumbers[item.key]" :is-integer="true" v-if="item.type === 'digit'" class="item-stepper"/>
            <div class="tips-box" v-if="item.tips">
              <img :src="renderImg(item.tips)" :alt="item.tips" class="tips-image" @click="showPreview(item.tips)">
            </div>
			<div class="search-box" v-if="item.type === 'text' && item.searchr">
				<div class="list" v-for="i in selectOptions" @mousedown="enterSelect(i)">{{i}}</div>
				<div class="list" v-if="!selectOptions.length">无匹配内容</div>
			</div>
          </div>
        <!--   select setting       -->
          <md-field-item v-if="item.type === 'select' && !item.updata" :content="selectModalValue[item.key]" arrow solid @click="riseSelector(item.key)"/>
          <div class="modify" v-if="item.updata">{{item.value}}</div>
          <md-selector
              v-if="item.type === 'select'"
              v-model="selectModel[item.key]"
              :data="item.data"
              max-height="200px"
              :title="'请选择' + item.name"
              @choose="getSelectorValue"
          ></md-selector>
        </div>
      </div>
    </div>

  <!-- add 附加选项part   -->
    <div class="additional-part">
      <md-button size="small" round inline plain class="add-parts-btn" color="#E97917" @click="visible = true">
        <md-icon name="wrong" size="sm" class="add-icon" color="#E97917"></md-icon>
        添加配件
      </md-button>
    </div>

  <!-- additional part show box   -->
    <div class="additional-parts-box">
      <div class="flex add-list" v-for="aItem in additionalList">
        <div class="add-parts-name">{{aItem.part_name}}:</div>
<!--        <input type="number" class="addition-num-input" v-model="additionParts[aItem.part_id]" placeholder="请填写需要的数量"/>-->
        <md-stepper v-model="additionParts[aItem.part_id]" :min="1" :is-integer="true" class="item-stepper"/>
        <md-button type="warning" size="small" icon="close" inline round plain @click="removeAddition(aItem.part_id)">删除</md-button>
      </div>

    </div>

    <md-button
        type="primary"
        size="small"
        class="setting-one-add-btn"
        style="width: 100%;margin: 0 auto"
        @click="confirmSubmit"
    >提交</md-button>

    <parts-modal :visible="visible" :ex-parts="additionalList" @close="visible = false" @getConfigSetting="getAdditionParts"/>

  </div>
</template>

<script>
import axios from "axios";
import ApiConfig from "../../assets/config/api.config";
import partsModal from "../../components/partsModal.vue";
import {Toast} from "mand-mobile";
const formDataHeader = {'Content-Type': 'application/x-www-form-urlencoded'};
let staff_id = 0;
let team_id = 0;
let contract_id = 0;
let lot_id = 0;
let scheme = 'http';
let path = 'www.icnctp.com';
export default {
  name: "applyParts",
  components: {
    PartsModal: partsModal
  },
  data() {
    return {
      visible: false,
      formController: [
        {name: '合同名称', type: 'select', key: 'contract_id', data: [],updata:true,value:''},
        {name: '批次编号', type: 'select', key: 'lot_id', data: [],updata:true,value:''},
        {name: '收货人', type: 'text', key: 'receive_name' ,searchr:false,kind:'name'},
        {name: '收货人电话', type: 'text', key: 'receive_phone',searchr:false, kind:'phone'},
        {name: '收货地址', type: 'text', key: 'receive_address',searchr:false, kind:'address'},
		{name: '控制盒型号', type: 'select', key: 'box_type', data: [
		    {text: '2.HCTW05L902/八代平板锁控制盒', value: '1'}
		]},
		{name: '控制盒数量', type: 'digit', key: 'apply_box_num', tips: 'control_box'},
		{name: '电机型号', type: 'select', key: 'motor_type', data: [
		    {text: 'FCBW020001/电机', value: '1'},
		    {text: '1.FCBW020002/七代平板锁配套电机左', value: '2'},
		    {text: '1.FCBW020003/七代平板锁配套电机右', value: '3'},
		    {text: '2.HCBM020006/八代电机仓组件', value: '4'},
		]},
        {name: '电机数量', type: 'digit', key: 'apply_motor_num', tips: 'motor'},
		{name: '电池型号', type: 'select', key: 'battery_type', data: [
		    {text: '1.FCBW010002/七代平板锁配套蓄电池', value: '1'},
		    {text: '1.FCBW010003/七代平板锁配套低温电池', value: '2'},
		    {text: '2.HCBW010001/八代锁锂电池组件', value: '3'},
		]},
		{name: '电池数量', type: 'digit', key: 'apply_battery_num', tips: 'battery'},
        // {name: '产品型号', type: 'select', key: 'apply_type', data: [
        //     {text: '六代锁', value: '1'},
        //     {text: '七代锁', value: '2'},
        //     {text: '八代锁', value: '3'},
        //     {text: '贴地锁', value: '4'},
        //   ]},
      ],
      selectModel: {
        contract_id: false,
        lot_id: false,
        // apply_type: false,
		box_type:false,
		motor_type:false,
		battery_type:false
      },
      currentSelector: '', // 当前选择项
      selectModalValue: {
        contract_id: '',
        lot_id: '',
        // apply_type: '',
		box_type:'',
		motor_type:'',
		battery_type:''
      },
      partsForm: {
		  apply_box_num: 0,
		  apply_motor_num: 0,
		  apply_battery_num: 0
	  },
      additionalList: [],
      additionParts: {},
      maxNumbers: { // 控制盒/电机/电池集合
        apply_box_num: 0,
        apply_motor_num: 0,
        apply_battery_num: 0
      },
	  focusItem:{},
	  selectOptions:[]
    }
  },
  mounted() {
    document.title = '申请配件';
    team_id = this.$route.query.team_id || 0;
    staff_id = this.$route.query.staff_id || 0;
    contract_id = this.$route.query.contract_id || 0;
    scheme = this.$route.query.scheme || 'http';
    path = this.$route.query.path || 'www.icnctp.com';
    lot_id = this.$route.query.lot_id || 0;
	this.partsForm.contract_id = contract_id
	this.partsForm.lot_id = lot_id
	this.partsForm.scheme = scheme
	this.partsForm.path = path
    this.getPartsInfo({
      type: 'contract',
      team_id: team_id
    })
    this.getLot({
      type: 'lot',
      contract_id: contract_id
    });
    this.getMaxNumber({
      type: 'max_num',
      lot_id: lot_id
    })
  },
  methods: {
	  openSearch(item,index){
		  this.formController[index].searchr = true
		  this.focusItem = item
		  this.selectOptions = []
		  this.getAddressInfo()
	  },
	  searchInput(e){
		// console.log(e)
		let value = this.partsForm[this.focusItem.key]
		this.getAddressInfo(value)
	  },
	  async getAddressInfo(searchValue){
		  let query = {}
		  if(searchValue){
			  query = {
				  lot_id:this.$route.query.lot_id,
				  type:this.focusItem.kind,
				  search:searchValue
			  }
		  }else{
			  query = {
				  lot_id:this.$route.query.lot_id,
				  type:this.focusItem.kind
			  }
		  }
		let res = await axios.post(ApiConfig.getAddresseeInfo,this.queryToFormData(query),{headers: formDataHeader})  
		console.log(res)
		if(res.data.code == 0){
			let data = res.data.content
			this.selectOptions = data[this.focusItem.kind]
		}
		
	  },
	  enterSelect(e){
		  console.log(e)
		this.partsForm[this.focusItem.key] = e
	  },
    // 获取合同信息
    getPartsInfo(query) {
      axios.post(ApiConfig.getPartsInfo, this.queryToFormData(query), {headers: formDataHeader}).then(({data}) => {
        if(data.code === 0) {
          let contracts = data.content.contracts;
          contracts.map(c => {
            this.formController[0].data.push({
              text: c.project_name,
              value: c.id
            })
            if(contract_id == c.id){
              this.formController[0].value = c.project_name
            }
          })
        }
      })
    },
    // 获取合同信息
    getLot(query) {
      let data = this.queryToFormData(query);
      axios.post(ApiConfig.getPartsInfo, this.queryToFormData(query), {headers: formDataHeader}).then(({data}) => {
        if(data.code === 0) {
          let lots = data.content.lots;
          lots.map(l => {
            this.formController[1].data.push({
              text: l.lot_code,
              value: l.lot_id
            })
            if(lot_id == l.lot_id){
              this.formController[1].value = l.lot_code
            }
          })
        }

      })
    },
    // 获取控制盒/电机/电池的最大数量
    getMaxNumber(query) {
      axios.post(ApiConfig.getPartsInfo, this.queryToFormData(query), {headers: formDataHeader}).then(({data}) => {
        this.maxNumbers.apply_box_num = data.content.max_nums.control_box;
        this.maxNumbers.apply_motor_num = data.content.max_nums.motor;
        this.maxNumbers.apply_battery_num = data.content.max_nums.battery;
      })
    },
    // 获取selector结果
    getSelectorValue(val) {
      // 重置max对象
      // this.clearMax();
      this.partsForm[this.currentSelector] = val.value;
      this.selectModalValue[this.currentSelector] = val.text;
      if(this.currentSelector === 'contract_id') {
        // 清空批次选项
        this.formController[1].data = [];
        this.partsForm['lot_id'] = '';
        this.selectModalValue['lot_id'] = '';
        // 调用批次查询
        this.getLot({
          type: 'lot',
          contract_id: val.value
        });
      }
      if(this.currentSelector === 'lot_id') {
        this.getMaxNumber({
          type: 'max_num',
          lot_id: val.value
        })
      }
    },
    // 唤起selector
    riseSelector(key) {
		console.log(key)
      this.currentSelector = key;
      this.selectModel[key] = true;
    },
    queryToFormData(query) {
      let params = new FormData();
      for(let q in query) {
        params.append(`${q}`, query[q]);
      }
      return params;
    },
    // 获取选择的其他配件信息
    getAdditionParts(info) {
      this.additionalList = info;
    },
    // 去除选中的对象
    removeAddition(id) {
      this.additionalList = this.additionalList.filter(item => item.part_id !== id);
      delete this.additionParts[id];
    },
    // clear submit info
    clearAll() {
      this.selectModalValue = {
        contract_id: '',
        lot_id: '',
        // apply_type: '',
		box_type:'',
		motor_type:'',
		battery_type:''
      };
      this.clearMax();
      this.partsForm = {};
      this.additionalList = [];
      this.additionParts = {};
    },
    // 清空max值对象
    clearMax() {
      this.maxNumbers = {
        apply_box_num: 0,
        apply_battery_num: 0,
        apply_motor_num: 0
      }
    },
    // 确认提交
    confirmSubmit() {
		let items = ['contract_id', 'lot_id', 'apply_box_num', 'apply_motor_num', 'apply_battery_num'];
		if(this.partsForm.apply_box_num){
			items.push('box_type')
		}else{
			items = items.filter(item => item != 'box_type')
		}
		if(this.partsForm.apply_motor_num){
			items.push('motor_type')
		}else{
			items = items.filter(item => item != 'motor_type')
		}
		if(this.partsForm.apply_battery_num){
			items.push('battery_type')
		}else{
			items = items.filter(item => item != 'battery_type')
		}
		console.log(items)
		console.log(this.partsForm)
      // 做简单的校验
      if(!this.checkFormItem(items)) {
        Toast.info('请填写完整申请信息');
        return false;
      }
      let url = ApiConfig.applyParts;
      // 处理addition parts 数据
      let arr = [];
      for(let a in this.additionParts) {
        arr.push(`${a}-${this.additionParts[a]}`)
      }
      this.partsForm.addArr = arr.join(',');
      this.partsForm.team_id = team_id;
      this.partsForm.staff_id = staff_id;
      this.partsForm.contract_id = contract_id;
      this.partsForm.lot_id = lot_id;
      let params = this.queryToFormData(this.partsForm);
      axios.post(url, params, {headers: formDataHeader}).then(({data}) => {
        if(data.code === 0) {
          Toast.info('申请成功');
		  // console.log("申请成功",data)
          // clear info;
          this.clearAll();
		  let path = data.content.path
		  if(path){
			  location.href = path
		  }
        }else {
          Toast.info(data.msg);
        }

      })

    },
    // 校验
    checkFormItem(items) {
      let res = true;
      // let items = ['contract_id', 'lot_id', 'apply_box_num', 'apply_motor_num', 'apply_battery_num', 'box_type','motor_type','battery_type'];
      let keys = Object.keys(this.partsForm);
      items.map(i => {
        if(!keys.includes(i)) {
          res = false;
          return false
        }
      })
      return res;
    },
    // 动态渲染图片
    renderImg(name) {
      return require('./../../assets/images/parts/' + name + '.png')
    },
    // 图片预览
    showPreview(name) {
		console.log(name)
	}
  },

}
</script>

<style lang="less" src="./../Setting/setting.less"></style>
<style lang="less" scoped>
.setting-item {
  align-items: center;
}
.setting-tips {
  width: 3rem;
  font-weight: 700;
}
.modify{
  color: #666;
  font-size: 0.4rem;
  line-height: 0.9rem;
  text-align: right;
}
.fields-input {
  border: 1px solid transparent;
  &:focus {
    border: none !important;
  }
}
.md-field-item {
  width: 6rem;
}

.add-parts-btn {
  width: 30%;
  border: 1px solid #E97917;
  margin-left: 0.5rem;
  color: #E97917 !important;
}
.add-icon {
  transform: rotate(45deg);
}

.additional-parts-box {
  margin-bottom: 1rem;
  .add-list {
    margin: 0 0.45rem;
    padding: 0.2rem 0;
    font-size: 0.4rem;
    align-items: center;
    justify-content: space-between;
    color: #666;
    .add-parts-name {
      width: 2.8rem;
    }
    .addition-num-input {
      height: 0.8rem;
      width: 4rem;
      margin: 0 0.2rem;
    }
  }
}
.input-box {
  position: relative;
  display: flex;
  .item-stepper {
    width: 5.5rem;
  }
  .tips-box {
    font-size: 0.3rem;
    color: #EC6A00;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    .tips-image {
      width: 1.5rem;
      margin-left: 0.5rem;
    }
  }
  .search-box{
	  width: 100%;
	  // border:1px solid #999;
	  background-color: #fff;
	  border-radius: 5px;
	  position: absolute;
	  top: 1rem;
	  z-index: 9;
	  padding:5px;
	  overflow-x: auto;
	  box-shadow: 0px 0px 10px #666;
	  .list{
		  font-size: 0.4rem;
		  color:#666;
		  margin:4px 5px;
		  overflow: hidden;
		  text-overflow: ellipsis; 
		  white-space: nowrap;
	  }
  }
}


</style>
